.display-event-container {
  display: flex;
  min-height: 50px;
  align-items: center;
  .display-event-empty {
    width: 10px;
  }

  .event-icon {
    padding-top: 4px;

    svg > path {
      fill: black;
    }
  }
  .display-event-empty-middle {
    width: 30px;

  }

  .display-event-time{
    font-family: 'Manrope' !important;
    font-size: 20px;
  }

  .display-event-body {
    margin-top: 6px;
    width: 40%;


    .display-event-name {
      font-family: 'Heebo';
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
    }

    .display-event-description {
      font-family: 'Heebo';
      font-size: 14px;
      line-height: 21px;
    }

    .display-event-where {
      font-family: 'Heebo';
      font-size: 14px;
      line-height: 21px;
    }

    .display-event-by {
      font-family: 'Heebo';
      font-size: 14px;
      line-height: 21px;
    }

    .display-event-link {
      font-family: 'Heebo';
      font-size: 14px;
      line-height: 21px;
    }
  }

  .display-event-more-less {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    width: 40px;
    color: #058ED9;

  }


  .display-event-actions {
    .display-event-action {
      margin: 2px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 10px;
      gap: 10px;

      width: 77px;
      height: 20px;

      background: #058ED9;
      border-radius: 2px;
      color: white;

      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }

    .save-to-calender {
      font-size: 10px;

    }
  }

  .divider-event {
    height: 0.5px;
    border: none;
    border-radius: 6px;
    background: #C2EAFF;
  }
}

.display-event-container.past-date {
  *{
    color: rgba(0, 0, 0, 0.3);
    fill: rgba(0, 0, 0, 0.3) !important;
  }
}

.is-nearest-event {
  background: #C2EAFF;
}